import * as React from "react"

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const SectionSix= () => {

    return(
    <>
    <section className="testmonialSec home_page2 overlayw10" id="testmonialSec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div id="testmonialCaro" className="home_page2">
                            <AwesomeSlider play={true}>
                            <div className="singleTestmn">
                                <p>Great service and our finished product is a professional high quality video!
Very easy to communicate with and get our ideas across. We would highly recommend</p>
                                <div className="testAut">
                                    <h4>Mark Parkinson</h4>
                                    <p>Bolton - UK</p>
                                </div>
                            </div>
                            <div className="singleTestmn">
                                <p>Rhoni Vision Production produces high quality work ! I requested their service for a Fitness app video presentation, and
the result was outstanding. Thanks again !</p>
                                <div className="testAut">
                                    <h4>Aurelien Chéruel</h4>
                                    <p>Paris - France</p>
                                </div>
                            </div>
                             <div className="singleTestmn">
                                <p>Very professional, creative and thoughtful, most important quick to deliver if required and easy to deal with.
I have done 74 videos so far, please feel free to visit my youtube channel under the name of ctclinic.</p>
                                <div className="testAut">
                                    <h4>Ammar Sawaf</h4>
                                    <p>Manchester - UK</p>
                                </div>
                            </div> 
                            <div className="singleTestmn">
                                <p>Rhoni Vision has treated me, my staff and the business as the most important business in the world, their attitude, preparation and
attention to details is amazing during the whole process. Best video production company I’ve ever worked with.</p>
                                <div className="testAut">
                                    <h4>Catherine Machalinski</h4>
                                    <p>Rochedale - UK</p>
                                </div>
                            </div> 
                            </AwesomeSlider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
                        }
export default SectionSix