import * as React from "react"

const SectionTree= () => (
    <>
    {/* <!--WHAT WE DO START--> */}
        <section className="whatwe home_page2" id="whatwe">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-lg-offset-1 text-center">
                        <div className="wedoContent">
                            <h5 className="text-uppercase color_white bold">what we do</h5>
                            <h2 className="lead color_white">we help businesses evolve <span>&</span> grow.</h2>
                            <a href="/#" className="bes_button2"> Find out more<i className="flaticon-arrows"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--WHAT WE DO END--> */}
   </>
)

export default SectionTree
