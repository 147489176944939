import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SectionTwo= () => (
    <>
  <section className="team" id="team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sectionTitle epr200">
                            <h5 className="magenta text-uppercase bold">meet the team</h5>
                            <h3 className="lead">Great stories that makes your brand stand out.</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="teamTop">
                        <div className="col-sm-6">
                            <div className="teamBig">
                                <StaticImage src="../../images/images/team/jaefar_director.png" alt="Rhonivision Creative Director - Jaefar Rhoni" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="testmonial" id="testmonial">
                                <div className="singleTestm">
                                    <p>
                                    We've produced videos and digital content for local and major business, organisations and charities all over the North West.
                                    </p>
                                    <div className="teamDeg">
                                        <h4 className="magenta">Jaefar Rhoni</h4>
                                        <p>Creative Director</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3 sm_className">
                        <div className="singleTeam">
                            <StaticImage src="../../images/images/team/avatar.png" alt="Rhonivision Senior Account Director - Imane Saddaoui" />
                            <div className="teamDeg">
                                <h4>Imane Saddaoui</h4>
                                <p>Senior Account Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 sm_className">
                        <div className="singleTeam">
                            <StaticImage src="../../images/images/team/p2oussama.png" alt="Rhonivision Head of IT - Oussama Rhoni" />
                            <div className="teamDeg">
                                <h4>Oussama Rhoni</h4>
                                <p>Head of IT</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 sm_className">
                        <div className="singleTeam">
                            <StaticImage src="../../images/images/team/p3monaim.png" alt="Rhonivision Digital Content Producer - Mounaim El badaoui" />
                            <div className="teamDeg">
                                <h4>Mounaim El badaoui</h4>
                                <p>Digital Content Producer</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 sm_className">
                        <div className="singleTeam">
                            <StaticImage src="../../images/images/team/p4srefano.png" alt="Rhonivision Digital Marketing Executive - Stefano Miali" />
                            <div className="teamDeg">
                                <h4>Stefano Miali</h4>
                                <p>Digital Marketing Executive</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </>
)

export default SectionTwo
