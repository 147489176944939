import * as React from "react"
import Recaptcha from 'react-google-recaptcha'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SectionNine= () => {

    const [state, setState] = React.useState({})
    const [buttonDisabled, setButtonDisabled] = React.useState(true)

    const RECAPTCHA_KEY = '6LfBL24eAAAAAEnLxb8f4Imt4rssFtkwUu4igNcO'

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
      }

      const recaptchaRef = React.createRef() // new Ref for reCaptcha

    function encode(data) {
        return Object.keys(data)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&");
      }
    const handleSubmit = (e) => {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue()
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
            "form-name": e.target.getAttribute("name"),
            'g-recaptcha-response': recaptchaValue,
            ...state
            }),
        })

          .then(() => toast.success("Form successfully submitted"))
          .then(
            document.getElementById("contactForm").reset()
          )
          .catch((error) => toast.error("error" + error));
      };

    return(    
    <>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        <section className="contact home_page2" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="contactInner">
                            <h3 className="magenta light">Have a question? or just say hello!</h3>
                            <div className="contactForm">
                                <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true" id="contactForm" onSubmit={handleSubmit}>
                                <input type="hidden" name="form-name" value="contact" />

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="single_form home_page2">
                                                <label for="con_name" aria-label="Name" >*Name:</label>
                                                <input type="text" name="con_name" id="con_name" className="required" required onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="single_form home_page2">
                                                <label for="con_phone" aria-label="Phone">Phone:</label>
                                                <input type="text" name="con_phone" id="con_phone" className="required" onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="single_form home_page2">
                                                <label for="con_email" aria-label="Email" >*Email:</label>
                                                <input type="email" name="con_email" id="con_email" onChange={handleChange} className="required" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="single_form home_page2">
                                                <label for="con_company" aria-label="Company">Company:</label>
                                                <input type="text" name="con_company" id="con_company" className="required" onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="single_form home_page2">
                                                <label for="con_msg" aria-label="Message">Message:</label>
                                                <textarea id="con_msg" name="con_msg" className="required" onChange={handleChange}></textarea>
                                            </div>
                                        </div>
                                        
                                            <Recaptcha
                                                ref={recaptchaRef}
                                                sitekey={RECAPTCHA_KEY}
                                                size="normal"
                                                id="recaptcha-google"
                                                onChange={() => setButtonDisabled(false)} // disable the disabled button!
                                                />
                                        <div className="col-sm-12 text-center">
                                            <button  type="submit" value="SEND" id="con_submit" disabled={buttonDisabled} className="bes_button2" >SEND</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>       
    </>
)
}
export default SectionNine