import * as React from "react"


import Bolton from "../../images/images/say/Bolton.jpg"
import Alicante from "../../images/images/say/Alicante.jpg"


const SectionEight= () => (
    <>
                 <section className="sayhello" id="sayhello">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="sectionTitle bigTitle2">
                            <h5 className="text-uppercase bold">we would love to hear from you</h5>
                            <h2 className="lead">say hello!</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="singleSay saypaddingR text-center">
                            <div className="sayThumb">
                                <img src={Bolton} alt="Rhoni vision video production Bolton office" title="Rhoni vision video production Bolton office" />
                                <h3 className="text-uppercase">Bolton</h3>
                            </div>
                            <div className="sayDec">
                                <h6 className="bold text-uppercase">Bolton Office</h6>
                                <p>Flexspace, Office 30, Manchester Road</p>
                                <p>BL3 2NZ Bolton - United Kingdom</p>
                                <h6 className="bold"><a href="tel:+441615052760">+44 161 50 52 760</a> </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="singleSay saypaddingl text-center">
                            <div className="sayThumb">
                                <img src={Alicante} alt="Rhoni vision video production Alicante office" title="Rhoni vision video production Alicante office" />
                                <h3 className="text-uppercase">Alicante</h3>
                            </div>
                            <div className="sayDec">
                                <h6 className="bold text-uppercase">Alicante Office</h6>
                                <p>Calle Virgen Pilar, 62</p>
                                <p>03110 Mutxamel Alicante - Spain</p>
                                <h6 className="bold"><a href="tel:+34642432140">+34 642 43 21 40</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
export default SectionEight