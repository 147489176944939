import * as React from "react"

import "../assets/css/bootstrap.css"
import "../assets/css/font-awesome.css"
import "../assets/css/owl.theme.css"
import "../assets/css/animate.css"
import "../assets/css/slick.css"
import "../assets/css/flaticon.css"
import "../assets/css/settings.css"
import "../assets/css/style.css"
import "../assets/css/preset.css"
import "../assets/css/responsive.css"

import MainMenu from "../components/MainMenu/Mainmenu"
import VideoBg from "../components/videoBg/VideoBg"
import SectionOne from "../components/SectionOne/SectionOne"
import SectionTwo from "../components/SectionTwo/SectionTwo"
import SectionTree from "../components/SectionTree/SectionTree"
import SectionSix from "../components/SectionSix/SectionSix"
import SectionSeven from "../components/SectionSeven/SectionSeven"
import SectionEight from "../components/SectionEight/SectionEight"
import SectionNine from "../components/SectionNine/SectionNine"
import SectionTen from "../components/SectionTen/SectionTen"

import Seo from "../components/seo"

const IndexPage = () => (
    
    <>
    <Seo title="Home" />
    <MainMenu/>
    {/* <div className="preloader">
        <div class="loader">
            <img src={Loader} alt="" />
        </div>
    </div> */}
    <VideoBg/>
    <SectionOne/>
    <SectionTwo/>
    <SectionTree/>
    {/* <SectionFour/> */}
    <SectionSix/>
    <SectionSeven/>
    <SectionEight/>
    <SectionNine/>
    <SectionTen/>
   </>
)

export default IndexPage
