import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

const SectionSeven= () => (
    <>
    <section className="client home_page2" id="client">
            {/* <div className="perelaxBg5"></div> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="sectionTitle bigTitle3">
                            <h5 className="text-uppercase bold">who we worked for</h5>
                            <h2 className="lead">Our client experience</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_4.jpg" alt="Bury Hospice" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_3.jpg" alt="Bolton film festival" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_11.jpg" alt="Philip James Kennedy" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_14.jpg" alt="Sakando" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_16.jpg" alt="SOS Creativity" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_7.jpg" alt="Didsbury Mosque" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_5.jpg" alt="CF Cross-fit" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_13.jpg" alt="Prevail Accountancy" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_15.jpg" alt="Samis" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_10.jpg" alt="Oxford Grove Football Club" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_12.jpg" alt="QEST" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_17.jpg" alt="ZI Animal Academy" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_9.jpg" alt="Nova Alarms" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_6.jpg" alt="CT clinic" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_8.jpg" alt="Fit choclate bars" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="singleClient">
                            <StaticImage src="../../images/images/client/client_logo_2.jpg" alt="The Avenue by FKZ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
export default SectionSeven