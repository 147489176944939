import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SectionOne = () => (
    <>
    <section className="about about2" id="about">
            <div className="perelaxBg1"></div>
            <div className="aboutTop home_page2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="abcontentT">
                                <h5 className="text-uppercase magenta bold">Discover Rhoni Vision</h5>
                                <h3 className="lead light">
                                    a creative studio that is specialized in brand <br/> strategy and digital creation.
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="abBigtitle home_page2">
                <h1 className="lead">Strategy.Brand.Design</h1>
            </div>
            <div className="aboutBottom home_page2" id="services">
                <div className="perelaxBg2"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <div className="abcontentB noPadding">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <h5 className="lead bold">We take a bottom-line approach to each project.</h5>
                                    </div>
                                    <div className="col-lg-7">
                                        <h3 className="light">
                                            Our clients consistently see increased traffic, enhanced brand loyalty and new leads thanks to our work.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ourServices">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">

                                    <StaticImage src="../../images/images/services/Video_Marketing.jpg" alt="Video marketing"/>
                                </div>
                                <h6 className="lead text-uppercase bold">Video marketing</h6>
                                <p>Video increase conversions by 80% regardless of the category in which you deploy it.<br/><br/></p>
                                <Link to="/videoMarketing" className="learn">Learn more</Link>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">
                                    <StaticImage src="../../images/images/services/Digital_Marketing.jpg" alt="Digital marketing"/>
                                </div>
                                <h6 className="lead text-uppercase bold">Digital marketing</h6>
                                <p>Online Marketing, Social Branding, Search Engine Optimization, Pay-Per-Click, Email Marketing, Google
Adwords.</p>
                                {/* <a href="/digital-marketing" className="learn">Learn more</a> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">
                                    <StaticImage src="../../images/images/services/Film_Producing.jpg" alt="Film producing"/>
                                </div>
                                <h6 className="lead text-uppercase bold">Film producing</h6>
                                <p>“Storytelling is the greatest technology that humans have ever created.” – Jon Westerberg<br/><br/></p>
                                {/* <a href="/film-producing" className="learn">Learn more</a> */}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                    <div className="row">
                    <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">

                                    <StaticImage src="../../images/images/services/Drone_services.jpg" alt="Aerial filming"/>
                                </div>
                                <h6 className="lead text-uppercase bold">Aerial filming</h6>
                                <p>Our CAA certified drone pilot have hundreds hours of flight times successfully completed.</p>
                                {/* <a href="/aerial-filming" className="learn">Learn more</a> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">
                                    <StaticImage src="../../images/images/services/360_3D_Tour.jpg" alt="3D virtual tour"/>
                                </div>
                                <h6 className="lead text-uppercase bold">3D virtual tour</h6>
                                <p>We help brands connect to their audience through shared values and interests.</p>
                                {/* <a href="/3d-virtual-tour" className="learn">Learn more</a> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                <div className="serImg">
                                    <StaticImage src="../../images/images/services/Website.jpg" alt=""/>
                                </div>
                                <h6 className="lead text-uppercase bold">Websites</h6>
                                <p>With a catalog of varied and complementary services we are sure to satisfy all your needs.</p>
                                <a href="/website" className="learn">Learn more</a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                </div>
            </div>
        </section>
   </>
)

export default SectionOne
