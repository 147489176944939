import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


import vid from "../../assets/vid/vidBg.mp4"

import '../../assets/css/firstPart.css'

const VideoBg = () => (
    <>
    <section className="vidContainer">

      <video autoPlay loop muted id="video">
        <source src={vid} type="video/mp4"/>
      </video>
     
        <div className="acroche">
              <div className="tp-caption sfb"
                             data-x="center"
                             data-y="center"
                             data-hoffset="0"
                             data-voffset="-40"
                             data-speed="1600"
                             data-start="1000"
                             data-easing="easeInOutCubic">
                            <div className="revCon">
                                <h5 className="text-uppercase color_white">we do nothing less than perfect</h5>
                            </div>
                        </div>
                        <div class="tp-caption sfb"
                             data-x="center"
                             data-y="center"
                             data-hoffset="0"
                             data-voffset="35"
                             data-speed="2000"
                             data-start="1500"
                             data-easing="Power4.easeOut">
                            <div class="revCon">
                                <h2 class="lead color_white">Adventure starts here.</h2>
                            </div>
                        </div>
                        </div>
                        <div class="tp-caption sfb"
                              style={{margin:"0 auto"}}
                              data-x="center"
                              data-y="center"
                              data-hoffset="0"
                              data-voffset="148"
                              data-speed="2000"
                              data-start="2000"
                              data-easing="Power4.easeOut">
                              <div class="revCon revBtn home_page2">
                                  <a href="#services" class="bes_button2"><span>Find out more<i class="flaticon-arrows"></i></span></a>
                              </div>
                          </div>
        <div className="scrollBtn">
          <StaticImage
          className="mouse"
            src="../../images/mouse.png"
            width={17}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="A Gatsby astronaut"
          />
        </div>
    </section>
   </>
)

export default VideoBg
